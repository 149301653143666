import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group font-bold top-28"
      position="top-right"
      closeButton={true}
      toastOptions={{
        classNames: {
          description:
            "group-[.toast]:text-slate-500 dark:group-[.toast]:text-slate-400",
          actionButton:
            "group-[.toast]:bg-white group-[.toast]:text-slate-50 dark:group-[.toast]:bg-slate-50 dark:group-[.toast]:text-slate-900",
          cancelButton:
            "group-[.toast]:bg-white group-[.toast]:text-slate-500 dark:group-[.toast]:bg-slate-800 dark:group-[.toast]:text-slate-400",
          closeButton:
            "group-[.toast]:bg-white group-[.toast]:border group-[.toast]:border-slate-200 group-[.toast]:text-slate-600 dark:group-[.toast]:bg-slate-800 dark:group-[.toast]:text-slate-400",
          error:
            "group toast group-[.toaster]:bg-white group-[.toaster]:border group-[.toaster]:border-rose-200 group-[.toaster]:text-rose-700",
          success:
            "group toast group-[.toaster]:bg-white group-[.toaster]:border group-[.toaster]:border-gray-200 group-[.toaster]:text-green-700 .toaster:font-bold",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
